// shonahigh.js
export const shonaexpertdata = [
  {
    title: 'Elite Package',
    description: 'Full Power Performance Hosting. Pake CPU dari Intel Xeon E-2388G dan NVMe SSD Storage.',
    packages: [
      {
        name: "Elite Package 1",
        price: 15000,
        cpu_percent: 800,
        ram: 1024,
        disk: 5120,
        backup_slots: 1,
        database_slots: 2
      },
      {
        name: "Elite Package 2",
        price: 30000,
        cpu_percent: 800,
        ram: 2048,
        disk: 10240,
        backup_slots: 2,
        database_slots: 2
      },
      {
        name: "Elite Package 3",
        price: 45000,
        cpu_percent: 800,
        ram: 4096,
        disk: 15360,
        backup_slots: 3,
        database_slots: 5
      },
      {
        name: "Elite Package 4",
        price: 63000,
        cpu_percent: 800,
        ram: 6144,
        disk: 25600,
        backup_slots: 5,
        database_slots: 7
      },
      {
        name: "Elite Package 5",
        price: 74000,
        cpu_percent: 800,
        ram: 8192,
        disk: 25600,
        backup_slots: 5,
        database_slots: 10
      },
      {
        name: "Elite Package 6",
        price: 115000,
        cpu_percent: 800,
        ram: 12288,
        disk: 25600,
        backup_slots: 5,
        database_slots: 12
      },
      {
        name: "Elite Package Pro",
        price: 150000,
        cpu_percent: 800,
        ram: 16384,
        disk: 40960,
        backup_slots: 10,
        database_slots: 15
      }
    ],
    faq: [
      {
        question: "Apa node ini dedicated CPU?",
        answer: "Tentu saja dedicated CPU, pada paket ini juga node kita menggunakan server fisik.",
      },
      {
        question: "Elite Package menggunakan CPU apa?",
        answer: "Kami menggunakan CPU Intel Xeon Intel E-2388G dengan clockspeed 5GHz yang membuat server anda sangat lancar walaupun banyak Plugin.",
      },
      {
        question: "Storage apa yang digunakan pada paket ini?",
        answer: "Kami menggunakan SSD NVMe pada paket ini. Kecepatan Read/Write hingga 1,2GB/s",
      },
      {
        question: "Apa perbedaannya dengan Paket Base?",
        answer: "Sudah jelas dari CPU dan Storage sangat berbeda! Performa juga pasti akan lebih stabil dan tinggi di Elite Package!",
      },
      {
        question: "Apa bisa diperpanjang?",
        answer: "Tentu saja server anda dapat diperpanjang kapanpun melalui menu setting di server.",
      },
      {
        question: "Apakah ada garansi paket ini?",
        answer: "Ya, kami memberikan garansi untuk semua produk Elite Package.",
      },
    ],
  },
];
