import React from 'react';
import minecraft from '../assets/minecraft-logo.png';
import pocketmine from '../assets/pocketmine-logo.png';
import papermc from '../assets/papermc-logo.png';
import rdr2 from '../assets/rdr2-logo.png';
import samp from '../assets/samp-logo.webp';
import terraria from '../assets/terraria-logo.png';
import fiveM from '../assets/FiveM-logo.png';
import docker from '../assets/docker-logo.png';

const ScrollAnimation = () => {
  return (
    <div className="mb-[150px] mt-[150px]">
        <div className="SAwrapper">
            <div className="SAitem SAitem1"><img src={minecraft} style={{width:'90px'}} alt="Minecraft" /></div>
            <div className="SAitem SAitem2"><img src={pocketmine} alt="PocketMine" style={{width:'90px'}} /></div>
            <div className="SAitem SAitem3"><img src={papermc} alt="PaperMC" style={{width:'90px'}} /></div>
            <div className="SAitem SAitem4"><img src={rdr2} alt="RDR2" style={{width:'90px'}} /></div>
            <div className="SAitem SAitem5"><img src={samp} alt="SAMP" style={{width:'100px'}} /></div>
            <div className="SAitem SAitem6"><img src={docker} alt="Docker" style={{width:'290px', marginTop:'25px'}} /></div>
            <div className="SAitem SAitem7"><img src={fiveM} alt="FiveM" style={{width:'170px'}} /></div>
            <div className="SAitem SAitem8"><img src={terraria} alt="Terraria" style={{width:'250px'}} /></div>
        </div>
    </div>
  );
};

export default ScrollAnimation;

const styles = `
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .SAwrapper {
    width: 90%;
    max-width: 1536px;
    margin-inline: auto;
    position: relative;
    height: 100px;
    overflow: hidden;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0)
    );
  }

  @keyframes scrollLeft {
    to {
      left: -200px;
    }
  }

  .SAitem {
    width: 200px;
    height: 100px;
    border-radius: 6px;
    position: absolute;
    left: max(calc(200px * 8), 100%);
    animation-name: scrollLeft;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .SAitem1 {
    animation-delay: calc(30s / 8 * (8 - 1) * -1);
  }

  .SAitem2 {
    animation-delay: calc(30s / 8 * (8 - 2) * -1);
  }

  .SAitem3 {
    animation-delay: calc(30s / 8 * (8 - 3) * -1);
  }

  .SAitem4 {
    animation-delay: calc(30s / 8 * (8 - 4) * -1);
  }

  .SAitem5 {
    animation-delay: calc(30s / 8 * (8 - 5) * -1);
  }

  .SAitem6 {
    animation-delay: calc(30s / 8 * (8 - 6) * -1);
  }

  .SAitem7 {
    animation-delay: calc(30s / 8 * (8 - 7) * -1);
  }

  .SAitem8 {
    animation-delay: calc(30s / 8 * (8 - 8) * -1);
  }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
