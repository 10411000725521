// shonahigh.js
export const shonahighdata = [
  {
    title: 'Base Package',
    description: 'Advanced Professional Minecraft Hosting. Using CPU from Intel Xeon E-2286G and SSD Storage.',
    packages: [
      {
        name: "Base Package 1",
        price: 5000,
        cpu_percent: 100,
        ram: 600,
        disk: 5120,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Base Package 2",
        price: 8000,
        cpu_percent: 200,
        ram: 1024,
        disk: 7168,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Base Package 3",
        price: 14000,
        cpu_percent: 200,
        ram: 2048,
        disk: 10240,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Base Package 4",
        price: 18000,
        cpu_percent: 400,
        ram: 3072,
        disk: 13312,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Base Package 5",
        price: 23000,
        cpu_percent: 400,
        ram: 4096,
        disk: 15360,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Base Package 6",
        price: 29000,
        cpu_percent: 600,
        ram: 6144,
        disk: 20480,
        backup_slots: 1,
        database_slots: 1,
      },
      {
        name: "Base Package 7",
        price: 35000,
        cpu_percent: 800,
        ram: 8192,
        disk: 25600,
        backup_slots: 1,
        database_slots: 2,
      },
      {
        name: "Base Package 8",
        price: 50000,
        cpu_percent: 800,
        ram: 12288,
        disk: 30720,
        backup_slots: 2,
        database_slots: 2,
      },
      {
        name: "Base Package 9",
        price: 75000,
        cpu_percent: 800,
        ram: 16384,
        disk: 40960,
        backup_slots: 2,
        database_slots: 5,
      },
    ],
    faq: [
      {
        question: "Apakah ada garansi paket ini?",
        answer: "Ya, kami memberikan garansi untuk semua produk Base Package.",
      },
      {
        question: "Apa saja metode pembayaran yang tersedia?",
        answer: "Kami memiliki metode pembayaran e-wallet dengan QRIS, Transfer Bank (Semua Bank), Kartu Debit/Kredit, Gopay, Dana, ShopeePay",
      },
      {
        question: "Base Package menggunakan CPU apa?",
        answer: "Kami menggunakan CPU Intel Xeon Intel E-2286G yang membuat server anda berjalan mulus.",
      },
      {
        question: "Storage apa yang digunakan pada paket ini?",
        answer: "Kami menggunakan SSD Sata pada paket ini. Kecepatan Read/Write hingga 650MB/s",
      },
      {
        question: "Apa bisa diperpanjang?",
        answer: "Tentu saja server anda dapat diperpanjang kapanpun melalui menu setting di server.",
      },
      {
        question: "Server Node CPanCloud apa masih dalam bentuk VM?",
        answer: "Tidak, Operating System semua server CPanCloud langsung diinstall pada server fisik (Dedicated).",
      },
    ],
  },
];
