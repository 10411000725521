// Basehigh.js
export const shonaSQLdata = [
  {
    title: 'Base SQL Database',
    description: 'Make Your Application Run Faster with our database hosting.',
    packages: [
      {
        name: "Base SQL Low",
        price: 30000,
        cpu_percent: 100,
        ram: 1024,
        datacenter: "Jakarta",
        maxconn: 1000,
        maxdb: 500,
      },
      {
        name: "Base SQL High",
        price: 50000,
        cpu_percent: 200,
        ram: 2048,
        datacenter: "Jakarta or Singapore",
        maxconn: "Unlimited",
        maxdb: 2000,
      },
    ],
    faq: [
      {
        question: "Fungsi Database apa?",
        answer: "Anda dapat menyimpan data. Misalnya, anda memiliki multi server Minecraft yang terhubung dengan 1 server proksi(Misal Server Minecraft Bungee/Waterfall/Waterdog). Anda dapat menghubungkan misalnya sistem uang/ekonomi ataupun yang lain pada game untuk semua server minecraft anda.",
      },
      {
        question: "Server SQLnya menggunakan CPU apa?",
        answer: "Kami menggunakan CPU Intel Xeon Intel Silver 4210R.",
      },
      {
        question: "Apa latensinya rendah?",
        answer: "Tergantung pada lokasi dan kondisi internet Server anda",
      },
      {
        question: "Apa bisa diperpanjang?",
        answer: "Tentu saja, anda dapat memperpanjang masa aktif.",
      },
    ],
  },
];
