export const termsofservice = {
    title: {
        en: "Terms of Service - Hosting Services",
        id: "Syarat dan Ketentuan - Layanan Hosting"
    },
    introduction: {
        en: "Welcome to CPanCloud's Terms of Service (TOS). This document serves as a core agreement between you and us, detailing the terms under which our services are provided.",
        id: "Selamat datang di Syarat dan Ketentuan (TOS) CPanCloud. Dokumen ini berfungsi sebagai perjanjian inti antara Anda dan kami, yang menguraikan ketentuan-ketentuan dalam penyediaan layanan kami."
    },
    sections: [
        {
            title: {
                en: "1. Resource Usage",
                id: "1. Penggunaan Sumber Daya"
            },
            content: {
                en: "Users must use CPU, RAM, and bandwidth within reasonable limits according to the selected service plan. Overuse of resources that causes server overload may result in temporary service restriction or termination without prior notice.",
                id: "Pengguna harus menggunakan CPU, RAM, dan bandwidth dalam batas yang wajar sesuai dengan paket layanan yang dipilih. Penggunaan sumber daya yang berlebihan hingga menyebabkan kelebihan beban server dapat menyebabkan pembatasan atau penghentian layanan sementara tanpa pemberitahuan terlebih dahulu."
            }
        },
        {
            title: {
                en: "2. Payment Policy and Service Termination",
                id: "2. Kebijakan Pembayaran dan Penghentian Layanan"
            },
            content: {
                en: "Hosting services are based on prepaid subscription terms. If payment is not received within 7 days of the renewal due date, the server or related services may be deactivated, and all stored data could be permanently deleted.",
                id: "Layanan hosting diberikan berdasarkan syarat berlangganan yang telah dibayar di muka. Jika pembayaran tidak diterima dalam 7 hari setelah jatuh tempo, server atau layanan terkait dapat dinonaktifkan, dan semua data yang tersimpan mungkin dihapus secara permanen."
            }
        },
        {
            title: {
                en: "3. User Privacy",
                id: "3. Privasi Pengguna"
            },
            content: {
                en: "We respect user privacy and are committed to safeguarding data confidentiality. User account security is the user's responsibility, and any activity from the user account is solely the user's responsibility.",
                id: "Kami menghormati privasi pengguna dan berkomitmen untuk menjaga kerahasiaan data. Keamanan akun pengguna adalah tanggung jawab pengguna, dan segala aktivitas yang berasal dari akun pengguna sepenuhnya menjadi tanggung jawab pengguna."
            }
        },
        {
            title: {
                en: "4. Limitation of Liability",
                id: "4. Batasan Tanggung Jawab"
            },
            content: {
                en: "The hosting provider is not responsible for damages, data loss, or other losses due to server disruptions, system failures, cyber-attacks, or misuse of the service.",
                id: "Penyedia layanan hosting tidak bertanggung jawab atas kerusakan, kehilangan data, atau kerugian lainnya akibat gangguan server, kegagalan sistem, serangan siber, atau penyalahgunaan layanan."
            }
        },
        {
            title: {
                en: "5. Disk Usage and Content",
                id: "5. Penggunaan Disk dan Konten"
            },
            content: {
                en: "Users are strictly prohibited uploading, storing, or distributing illegal content, such as pornography, illegal gambling, malware, or other criminal activities. We reserve the right to suspend or terminate user services that violate these terms, either based on reports from authorities or internal reviews. We may also report violations to the authorities if necessary. Users are fully responsible for the content they upload and agree that violations may result in service termination and legal action.",
                id: "Pengguna dilarang mengunggah, menyimpan, atau mendistribusikan konten ilegal, seperti pornografi, perjudian ilegal, malware dan sejenisnya, atau kegiatan kriminal lainnya. Kami berhak untuk menangguhkan atau menghentikan layanan pengguna yang melanggar ketentuan ini, baik berdasarkan laporan pihak berwenang atau pemeriksaan internal. Kami juga dapat melaporkan pelanggaran kepada pihak berwenang jika diperlukan. Pengguna bertanggung jawab penuh atas konten yang diunggah dan setuju bahwa pelanggaran dapat berakibat pada penghentian layanan dan tindakan hukum."
            }
        },
        {
            title: {
                en: "6. Abuse of Bugs or Vulnerabilities",
                id: "6. Penyalahgunaan Bug atau Kerentanan"
            },
            content: {
                en: "Users are strictly prohibited from exploiting bugs, vulnerabilities, or system weaknesses in any manner. Any abuse detected may result in immediate service suspension or termination without prior notice, and further legal actions may be pursued.",
                id: "Pengguna dilarang keras menyalahgunakan bug, kerentanan, atau kelemahan sistem dalam bentuk apa pun. Penyalahgunaan yang terdeteksi dapat mengakibatkan penangguhan atau penghentian layanan secara langsung tanpa pemberitahuan sebelumnya, serta tindakan hukum lebih lanjut dapat dilakukan."
            }
        },
        {
            title: {
                en: "7. Downtime Policy and Compensation",
                id: "7. Kebijakan Downtime dan Kompensasi"
            },
            content: {
                en: "In case of downtime, CPanCloud users are entitled to service uptime compensation. The compensation will be provided as an extension of service time based on downtime duration or money refund with the following calculation formula: Final Compensation = (Old Monthly Fee * Downtime Days / 30) * New Daily Fee / Average Daily Fee (Old and New)",
                id: "Jika terjadi downtime, pengguna layanan CPanCloud berhak mendapatkan kompensasi berupa penambahan masa aktif layanan, berdasarkan durasi downtime ataupun pengembalian dana dengan rumus perhitungan berikut: Kompensasi Akhir = (Biaya bulanan lama * Jumlah hari downtime / 30) * Biaya harian baru / Rata-rata biaya harian lama dan baru"
            }
        },
        {
            title: {
                en: "8. Upgrade Policy",
                id: "8. Kebijakan Upgrade"
            },
            content: {
                en: "By upgrading to a higher plan, the remaining subscription period will be adjusted based on the new plan's price, and the user will be charged the difference, or as decided by the hosting administrator with the following calculation formula: (Price of New Plan / Number of Days in New Plan) × Remaining Active Days - (Price of Old Plan / Number of Days in Old Plan) × Remaining Active Days.",
                id: "Dengan melakukan upgrade ke paket yang lebih tinggi, masa aktif yang tersisa akan disesuaikan dengan harga paket baru, dan pengguna akan dikenakan selisih biaya, atau diputuskan oleh administrator hosting dengan rumus perhitungan berikut: (Harga Plan Baru / Jumlah Hari di Plan Baru) × Sisa Hari Masa Aktif - (Harga Plan Lama / Jumlah Hari di Plan Lama) × Sisa Hari Masa Aktif."
            }
        },
        {
            title: {
                en: "9. Termination and Changes to Services",
                id: "9. Penghentian dan Perubahan Layanan"
            },
            content: {
                en: "The service provider reserves the right to terminate or amend service terms, with prior notification posted on the official website.",
                id: "Penyedia layanan berhak untuk menghentikan atau mengubah ketentuan layanan dengan pemberitahuan sebelumnya melalui situs web resmi."
            }
        },
        {
            title: {
                en: "10. Refund Policy",
                id: "10. Kebijakan Pengembalian Dana"
            },
            content: {
                en: "Refunds are only eligible under clear and valid reasons, and must be agreed upon by both parties. Users must provide a detailed explanation for the refund request, which will be evaluated before approval.",
                id: "Pengembalian dana hanya dapat dilakukan dengan alasan yang jelas dan valid, serta harus disepakati oleh kedua belah pihak. Pengguna harus memberikan penjelasan rinci untuk permintaan pengembalian dana, yang akan dievaluasi sebelum disetujui."
            }
        },
        {
            title: {
                en: "11. Legal Protection",
                id: "11. Perlindungan Hukum"
            },
            content: {
                en: "By using our services, users agree to indemnify and hold us harmless from any legal claims, lawsuits, damages, or penalties arising from misuse, illegal activities, or any breach of these Terms of Service.",
                id: "Dengan menggunakan layanan kami, pengguna setuju untuk membebaskan kami dari segala tuntutan hukum, gugatan, kerugian, atau hukuman yang timbul akibat penyalahgunaan, aktivitas ilegal, atau pelanggaran terhadap Syarat dan Ketentuan ini."
            }
        },
        {
            title: {
                en: "12. Legal Compliance",
                id: "12. Kepatuhan Hukum"
            },
            content: {
                en: "Users must comply with all applicable laws when using our services.",
                id: "Pengguna wajib mematuhi semua hukum yang berlaku saat menggunakan layanan kami."
            }
        },
        {
            title: {
                en: "13. Disclaimers",
                id: "13. Penafian"
            },
            content: {
                en: "The provider does not guarantee that the service will be uninterrupted, error-free, or completely secure.",
                id: "Penyedia layanan tidak menjamin bahwa layanan akan bebas dari gangguan, kesalahan, atau sepenuhnya aman."
            }
        },
    ]
};
