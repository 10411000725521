import { Database, Container, Server, ServerCrash, Earth, EthernetPort, Network, Inbox, Archive, Computer } from "lucide-react";

export const Menus = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Hosting",
    subMenuHeading: ["Game Hosting", "Other Hosting"],
    subMenu: [
      {
        name: "Base Package",
        desc: "Game Hosting kelas menengah",
        icon: Server,
        href: "/hosting/base-package",
      },
      {
        name: "Container Hosting",
        desc: "Hosting berbasis docker",
        icon: Container,
        href: "/hosting/docker-hosting",
      },
      {
        name: "Elite Package",
        desc: "Game Hosting performa tinggi",
        icon: Server,
        href: "/hosting/elite-package",
      },
      {
        name: "Database Hosting",
        desc: "Database Hosting SQL",
        icon: Database,
        href: "/hosting/database-hosting",
      },
      {
        name: "Bare Metal",
        desc: "Hosting dengan tingkat fisik",
        icon: Computer,
        href: "/hosting/bare-metal-package",
      },
    ],
    gridCols: 2,
  },
  {
    name: "Resources",
    subMenuHeading: ["Basic", "Network"],
    subMenu: [
      {
        name: "Domain",
        desc: "Daftarkan Domain Anda",
        icon: Earth,
        href: "/resources/tld",
      },
      {
        name: "VPN",
        desc: "Your Custom Private Network",
        icon: EthernetPort,
        href: "/resources/vpn",
      },
      {
        name: "Email Service",
        desc: "Buatlah Email dengan Domain anda",
        icon: Inbox,
        href: "/resources/email-service",
      },
      {
        name: "Game Proxy",
        desc: "Proxy server untuk anda",
        icon: Network,
        href: "/resources/reverse-proxy",
      },
      {
        name: "Object Storage",
        desc: "S3 Object Storage",
        icon: Archive,
        href: "/resources/object-storage",
      },
    ],
    gridCols: 2,
  },
  {
    name: "Terms",
    href: "/tos",
  },
  {
    name: "Contact",
    href: "/contact",
  },
];
