import { Cloud, Proportions, Package, Rabbit, CircleFadingArrowUp, ClockArrowUp, Rocket } from 'lucide-react';

export const featuresData = [
  {
    icon: <Rocket />,
    title: '99,9% Uptime',
    description: 'Tetap online 24/7 dengan jaminan uptime 99,9%. Kami pastiin server kamu selalu bisa diakses, jadi nggak ada kesempatan yang terlewat.',
  },
  {
    icon: <Proportions />,
    title: 'Manajemen Mudah Banget',
    description: 'Bikin pengalaman hosting kamu jadi simpel dengan control center yang gampang dipakai. Kelola server kamu dengan sangat mudah.',
  },
  {
    icon: <Package />,
    title: 'Instalasi Gampang',
    description: 'Mulai project kamu tanpa ribet pake fitur Instalasi Gampang. Mau itu server Minecraft atau aplikasi lain, semua bisa jalan cuma dengan beberapa klik.',
  },
  {
    icon: <Rabbit />,
    title: 'Performa Super Cepat',
    description: 'Rasain performa ngebut dengan server yang udah dioptimasi. Kami pastiin loading time minimal dan pengalaman smooth buat user atau client kamu.',
  },
  {
    icon: <CircleFadingArrowUp />,
    title: 'Scalable Resources',
    description: 'Gampang upgrade paket hosting kamu sesuai kebutuhan. Tambah storage, memory, atau CPU Core kapan aja, tanpa downtime.',
  },
  {
    icon: <ClockArrowUp />,
    title: 'Support Expert 24/7',
    description: 'Nikmatin ketenangan dengan tim support yang siap 24/7. Kalau ada masalah atau pertanyaan, kami siap bantu kapan aja, siang atau malam.',
  },
];
